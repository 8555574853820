import { Box, Flex, GatsbyImage, Text } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'

const UnauthorizedPage = () => {
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex maxWidth="1000px" flexDirection="column" margin="32px auto">
      <Flex justify="center">
        <GatsbyImage
          image={websiteContext?.logo?.image?.childImageSharp?.gatsbyImageData}
          alt="logo"
          loading="eager"
          style={{ maxWidth: '128px', height: 'auto' }}
        />
      </Flex>
      <Box m={{ base: '28px 16px 0', md: '28px 0 0' }}>
        <Text textAlign="center" fontSize="20px">
          Vous n&#39;êtes pas autorisé à accéder au site
          <Text as="span" fontWeight="600" p="0 6px">
            {websiteContext?.title}
          </Text>
        </Text>
      </Box>
    </Flex>
  )
}

UnauthorizedPage.pageType = 'unauthorized'

export default UnauthorizedPage
